/* src/pages/AboutUs.module.css */
.aboutUs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 20px;
  }
  
  .aboutUsImage {
    width: 300px;
    height: auto;
    border-radius: 10px;
  }
  
  .aboutUsText {
    max-width: 600px;
  }
  
  .aboutUsText h2 {
    margin-bottom: 10px;
  }
  
  .aboutUsText p {
    font-size: 1.2em;
    line-height: 1.5;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .aboutUs {
      flex-direction: column;
      text-align: center;
    }
  
    .aboutUsImage {
      width: 100%;
      max-width: 300px;
    }
  
    .aboutUsText {
      max-width: 100%;
    }
  }
  