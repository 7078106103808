@import '../variables.css';

.cookieBar {
  background-color: var(--cookies-background-color);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: auto; /* Adjust width as needed */
  max-width: 90%; /* Ensure it fits within the viewport */
  z-index: 1000;
  box-sizing: border-box; /* Ensures padding is included in the total width */
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for depth */
}

.cookieBar p {
  margin: 0;
  font-size: 14px; /* Slightly smaller font size for mobile */
  color: var(--cookies-text-color);
  flex: 1;
  text-align: center; /* Center the text */
}

.cookieBar a {
  color: var(--cookies-link-color);
  text-decoration: none;
  font-weight: var(--cookies-link-font-weight);
}

.cookieBar a:hover {
  text-decoration: underline;
}

.confirmButton {
  background-color: var(--button-secondary-background-color);
  color: var(--button-secondary-text-color);
  font-weight: var(--button-font-weight);
  border: none;
  padding: 5px 10px; /* Adjust padding for a smaller button */
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

.confirmButton:hover {
  background-color: var(--button-secondary-hover-background-color);
  color: var(--button-secondary-hover-text-color);
}

/* Media Query for mobile devices */
@media (max-width: 768px) {
  .cookieBar {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center align items */
    padding: 15px 20px; /* Adjust padding for mobile */
    bottom: 50%;
    transform: translate(-50%, 50%);
  }

  .cookieBar p {
    margin-bottom: 10px; /* Add margin below paragraph */
    width: 100%; /* Make paragraph take full width */
    text-align: center; /* Center align text */
  }

  .confirmButton {
    width: 100%; /* Make button take full width */
    text-align: center; /* Center text in the button */
    margin-left: 0; /* Remove left margin */
  }
}
