@import '../variables.css';
.hero {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 87vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #382826; /* White text color for better contrast */
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(196, 183, 159, 0.7); /* Transparent black layer */
    z-index: 1; /* Below the content */
  }
  
  .content {
    position: relative;
    z-index: 2; /* Above the overlay */
    max-width: 80%;
    margin: 0 auto;
  }
  
  .slogan {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .description {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .orderButton {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: var(--button-primary-text-color);
    background-color: var(--button-primary-background-color);
    font-weight: var(--button-font-weight);
    border: none;
    border-radius: var(--button-border-radius);
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 10px;
  }
  
  .orderButton:hover {
    background-color: var(--button-primary-hover-background-color);
    color: var(--button-primary-hover-text-color);
  }
  
  @media (max-width: 768px) {
    .slogan {
      font-size: 2rem;
    }
  
    .description {
      font-size: 1rem;
    }
  
    .orderButton {
      font-size: 1rem;
      padding: 8px 16px;
    }
  }
  